import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  countriesByLanguage,
  BOTONIC_STORAGE_KEY,
  botonicAppId,
  botonicUrl,
  languages,
  htmlHeadContents,
  HANDOFF_PAYLOAD,
} from './constants'
import { InMemoryStorage } from './domain/storage/in-memory-storage'
import { getPathData, isProduction } from './utils/env'
import './assets/styles/app-faq.scss'
import { getInitialPayload } from './utils/contents'
import {
  getEnsightenCookie,
  areCookiesAlowed,
  getUserIdFromCookie,
  updateUserIdCookieIfExists,
} from './utils/cookie'
import { BotonicState } from './domain/storage/botonic-state'
import { WebchatState } from './domain/storage/webchat-state'
import { v7 as uuid } from 'uuid'

declare global {
  interface Window {
    inMemoryStorage: Storage
    botonicOnInit: (app: any) => void | Promise<void>
    botonicOnOpen: (app: any) => void | Promise<void>
    botonicOnClose: (app: any) => void | Promise<void>
    botonicOnMessage: (app: any, message: any) => void | Promise<void>
  }
}

if (!window.inMemoryStorage) {
  window.inMemoryStorage = new InMemoryStorage()
}

const {
  language,
  languageSlug,
  sessionId,
  ivrException,
  initialContent,
  referral,
  queueId,
} = getPathData()
setLanguageInStorage(language)

const path = window.location.pathname
const channel = new BroadcastChannel(`broadcast${path}`)

function FaqBotApp() {
  useEffect(() => {
    loadBotonic('faq-webchatRoot', botonicUrl, {
      hostId: 'faq-webchatRoot',
      appId: botonicAppId,
      onInit: (app: any) => {
        const intervalId = setInterval(async () => {
          if (getEnsightenCookie() !== undefined) {
            clearInterval(intervalId)
            const user: { extra_data: Record<string, any>; id?: string } = {
              extra_data: {
                language,
                country: countriesByLanguage[language],
                sessionId,
                ivrException,
                referral,
              },
            }
            if (areCookiesAlowed()) {
              channel.postMessage('fetch')
              channel.onmessage = async event => {
                const actualUserId = getUserIdFromCookie()
                if (event.data === 'fetch') {
                  channel.postMessage(
                    JSON.stringify({
                      id: BotonicState.get().session?.user?.id,
                      name: BotonicState.get().session?.user?.name,
                      isDoingHandoff: WebchatState.get().isDoingHandoff,
                    })
                  )
                } else if (event.data.includes('@')) {
                  try {
                    const eventUser = JSON.parse(event.data)
                    if (eventUser.id === actualUserId) {
                      if (eventUser.isDoingHandoff) {
                        //We generate a new user id to avoid conflicts between multiple tabs while doing handoff
                        const newUserId = uuid()
                        updateUserIdCookieIfExists(newUserId)
                        return
                      }
            
                      app.updateUser({
                        name: eventUser.name,
                      })
                    }
                  } catch (error) {
                    console.error(error)
                  }
                }
              }
              await new Promise(resolve => setTimeout(resolve, 500))
              user.id = getUserIdFromCookie()
            }
            const enableUserInput = language === languages.ENGLISH
            app.updateUser(user)
            app.updateWebchatSettings({
              enableUserInput,
            })
            window.botonicOnInit(app)
            if (ivrException) {
              app.addUserPayload(HANDOFF_PAYLOAD)
            } else if (initialContent) {
              app.addUserPayload(getInitialPayload(initialContent, { queueId }))
            } else {
              app.openCoverComponent()
            }
            app.open()
          }
        }, 500)
      },
      onOpen: (app: any) => {
        const enableUserInput = language === languages.ENGLISH
        app.updateUser({
          extra_data: {
            language,
            country: countriesByLanguage[language],
          },
        })
        app.updateWebchatSettings({
          enableUserInput,
        })
        window.botonicOnOpen(app)
      },
      onClose: (app: any) => {
        window.botonicOnClose(app)
        app.open()
      },
    })
  }, [])

  const headTitle = htmlHeadContents[language].title
  const headDescription = htmlHeadContents[language].description

  const baseUrl = isProduction()
    ? 'https://www.easyjet.com/chat'
    : 'https://staging-easyjet.hubtype.com/chat'

  return (
    <div id='faq-main-app-container'>
      <Helmet>
        <link
          rel='canonical'
          href={`${baseUrl}/${languageSlug.toLowerCase()}`}
        />
        <link rel='alternate' href={`${baseUrl}/en`} hrefLang='en' />
        <link rel='alternate' href={`${baseUrl}/fr`} hrefLang='fr' />
        <link rel='alternate' href={`${baseUrl}/it`} hrefLang='it' />
        <link rel='alternate' href={`${baseUrl}/de`} hrefLang='de' />
        <link rel='alternate' href={`${baseUrl}/es`} hrefLang='es' />
        <link rel='alternate' href={`${baseUrl}/pt`} hrefLang='pt' />
        <link rel='alternate' href={`${baseUrl}/nl`} hrefLang='nl' />
        <title>{headTitle}</title>
        <meta name='description' content={headDescription} />
      </Helmet>
      <div id='faq-app-container'>
        <div id='faq-webchatRoot' />
      </div>
    </div>
  )
}

function setLanguageInStorage(language: string): void {
  const storage = window.inMemoryStorage.getItem(BOTONIC_STORAGE_KEY) || '{}'
  let storageJson = JSON.parse(storage)
  'session' in storageJson
    ? (storageJson.session.user.extra_data.language = language)
    : (storageJson = { session: { user: { extra_data: { language } } } })

  window.inMemoryStorage.setItem(
    BOTONIC_STORAGE_KEY,
    JSON.stringify(storageJson)
  )
}

function loadBotonic(
  elementId: string,
  botonicUrl: string,
  webchatConfig: Record<string, any>
) {
  const script = document.createElement('script')
  script.src = botonicUrl
  script.onload = () => executeBotonic(elementId, webchatConfig)
  document.head.appendChild(script)
}

function executeBotonic(elementId: string, webchatConfig: Record<string, any>) {
  //@ts-ignore
  Botonic.render(document.getElementById(elementId), webchatConfig)
}

export default FaqBotApp
